import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
// import SurveyOption from 'components/SurveyOption';
import { Results } from '.';
interface Props {
    onNext: (result?: Results) => void;
}

const Q7: React.FC<Props> = ({ onNext }) => {
    const [feedback, setFeedback] = useState('');
    const onClick = () => {
        if (feedback) {
            onNext({
                question7: feedback,
            });
        } else {
            onNext();
        }
    };

    const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(ev.target.value);
    };

    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 7 of 7</h2>
                <p>Please share any other feedback or suggestions you have in the box below</p>
                <textarea placeholder="Enter answer here..." value={feedback} onChange={onChange} />
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick}>Next</CustomerButton>
            </div>
        </>
    );
};

export default Q7;
