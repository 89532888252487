import React from 'react';
import styles from './loading.module.css';

const Loading: React.FC = () => {
    return (
        <section className={styles.loading}>
            <span>Loading</span>
        </section>
    );
};

export default Loading;
