import React, { useEffect, useMemo } from 'react';
import { useMeeting } from 'context/Meeting';
import { MeetingResponse } from 'services/api';
import { Button } from '@missionlabs/smartagent-app-components';
import { ResizableBox } from 'react-resizable';
import BlurIcon from 'images/blur.svg';
import EndIcon from 'images/end.svg';
import LocalVideo from 'components/LocalVideo';
import RemoteVideos from 'components/RemoteVideos';
import Draggable from 'react-draggable';
import './agent-meeting.scss';
import { useTiles } from 'context/Tiles';
import { useDevices } from 'context/Devices';

interface Props {
    meeting: MeetingResponse;
}

const AgentMeeting: React.FC<Props> = ({ meeting }) => {
    const { onStart, onEnd } = useMeeting();
    const { tiles } = useTiles();
    const { toggleBlur } = useDevices();

    useEffect(() => {
        onStart(meeting);
        // eslint-disable-next-line
    }, [meeting]);

    const hasRemoteVideos = useMemo(() => tiles.filter(t => !t.LocalTile).length > 0, [tiles]);

    return (
        <section className="agent-meeting">
            {!hasRemoteVideos && <div className="agent-meeting__overlay">NOT CONNECTED</div>}
            <ResizableBox
                width={document.body.clientWidth - 40}
                height={300}
                minConstraints={[100, 140]}
                maxConstraints={[document.body.clientWidth - 40, window.outerHeight]}
            >
                <div className="agent-meeting__remote">
                    <RemoteVideos />
                </div>
            </ResizableBox>
            <Draggable bounds="parent" handle=".local-video">
                <div className="agent-meeting__local">
                    <LocalVideo height={128} width={230} className="local-video" />
                    <div className="agent-meeting__controls">
                        <Button className="agent-meeting__blur" onClick={toggleBlur}>
                            <img src={BlurIcon} alt="Blur" />
                        </Button>
                        <Button className="agent-meeting__end" onClick={onEnd}>
                            <img src={EndIcon} alt="End" />
                        </Button>
                    </div>
                </div>
            </Draggable>
        </section>
    );
};

export default AgentMeeting;
