import React from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';

interface Props {
    onNext: () => void;
}

const Intro: React.FC<Props> = ({ onNext }) => {
    const onClick = () => {
        onNext();
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Thanks. Please help us improve.</h2>
                <p>
                    Please answer a few short questions about your this experience to help us
                    improve your future intteractions with us.
                </p>
                <p>Time to complete: 1 min</p>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick}>Next</CustomerButton>
            </div>
        </>
    );
};

export default Intro;
