import React, { useRef, useEffect, useContext } from 'react';
import { MeetingContext } from '../../context/Meeting';

interface Props {
    width?: number | string;
    height?: number | string;
    tileId: number;
    className?:string;
}

const Video: React.FC<Props> = ({ width, height, tileId, className }) => {
    const context = useContext(MeetingContext);
    const videoeEl = useRef<null | HTMLVideoElement>(null);

    useEffect(() => {
        context.audioVideo?.bindVideoElement(tileId, videoeEl.current!);
    }, [context.audioVideo, tileId]);

    return <video height={height} width={width} ref={videoeEl} className={className}/>;
};

export default Video;
