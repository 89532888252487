export interface Tile {
    ExternalId: string;
    AttendeeId: string;
    tileId: number;
    LocalTile: boolean;
}

export type State = {
    tiles: Tile[];
    selectedAttendee: string;
}

export enum VideoTileActionType {
    UPDATE,
    REMOVE,
    RESET,
    SELECT
}

type UpdateAction = {
    type: VideoTileActionType.UPDATE;
    payload: Tile;
};

type RemoveAction = {
    type: VideoTileActionType.REMOVE;
    payload: number;
};

type ResetAction = {
    type: VideoTileActionType.RESET;
    payload?: any;
};

type SelectAction = {
    type: VideoTileActionType.SELECT;
    payload: string;
};

export type Action = UpdateAction | RemoveAction | ResetAction | SelectAction;

export const initialState: State = {tiles:[],selectedAttendee:''};

export default function reducer(state: State, action: Action): State {
    switch (action.type) {
        case VideoTileActionType.UPDATE: {
            const isPresent = state.tiles.find(t => t.tileId === action.payload.tileId);

            if (isPresent) {
                return state;
            }

            return {...state,tiles:[...state.tiles, action.payload]};
        }
        case VideoTileActionType.REMOVE: {
            return {...state,tiles:state.tiles.filter(t => t.tileId !== action.payload)};
        }
        case VideoTileActionType.RESET: {
            return initialState;
        }
        case VideoTileActionType.SELECT: {
            return {
                ...state,
                selectedAttendee: action.payload
            }
        }
        default:
            throw new Error('Incorrect type');
    }
}
