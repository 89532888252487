import axios from 'axios';

const urls: Record<string, string> = {
    dev: 'https://dev-api.capita-videoassessment.com',
    prod: 'https://api.capita-videoassessment.com',
};

const baseURL = urls[process.env.REACT_APP_ENV!] || urls.dev;

export interface MeetingResponse {
    Meeting: any;
    Attendee: any;
}

export async function startMeeting(data: any) {
    const response = await axios({
        baseURL,
        url: '/meetings',
        method: 'POST',
        data,
    });
    return response.data as MeetingResponse;
}

export async function endMeeting(meetingId: string) {
    const response = await axios({
        baseURL,
        url: `/meetings/${meetingId}`,
        method: 'DELETE',
    });
    return response.data;
}
interface Join {
    id?: string;
}
export async function joinMeeting(meetingId: string, data: Join) {
    const response = await axios({
        baseURL,
        url: `/meetings/${meetingId}/attendees`,
        method: 'POST',
        data,
    });
    return response.data as MeetingResponse;
}

interface Invite {
    contactId?: string;
    email?: string;
    number?: string;
}
export async function sendInvite(meetingId: string, data: Invite) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}/invite`,
        method: 'POST',
        data,
    });
}

export async function deleteAttendee(meetingId: string, attendeeId: string) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}/attendees/${attendeeId}`,
        method: 'DELETE',
    });
}

export async function deleteMeeting(meetingId: string) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}`,
        method: 'DELETE',
    });
}

type Attributes = Record<string, string>;
export async function updateCTR(contactId: string, attributes: Attributes) {
    await axios({
        baseURL,
        url: `/contacts/${contactId}/attributes`,
        method: 'PUT',
        data: attributes,
    });
}
