import React from 'react';
import { Popup } from '@missionlabs/smartagent-app-components';
import CustomerButton from 'components/CustomerButton';
import EndIcon from 'images/end.svg';
// import BlurIcon from 'images/blur.svg';
import CameraIcon from 'images/camera.svg';
import './help.scss';

interface Props {
    onClose: () => void;
}
const Help: React.FC<Props> = ({ onClose }) => {
    return (
        <Popup closeOnClickOutside onClose={onClose} center className="help">
            <section className="help">
                <h2 className="title">Help</h2>
                {/* <div className="help__section">
                    <div className="help__icon">
                        <img src={BlurIcon} alt="Blur" />
                    </div>
                    <p>Switches the background blur on or off. Whilst on, anything behind you will be blurred.</p>
                </div> */}
                <div className="help__section">
                    <div className="help__icon">
                        <img src={CameraIcon} alt="End" />
                    </div>
                    <p>Switches between the front and rear facing cameras.</p>
                </div>

                <div className="help__section">
                    <div className="help__icon help__end">
                        <img src={EndIcon} alt="Swap Camera" />
                    </div>
                    <p>
                        Ends the video call. Once pressed the session will end and no-one will be
                        able to see you.
                    </p>
                </div>
                <CustomerButton onClick={onClose}>Close</CustomerButton>
            </section>
        </Popup>
    );
};

export default Help;
