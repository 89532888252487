import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
import SurveyOption from 'components/SurveyOption';
import { Results } from '.';

interface Props {
    onNext: (results: Results) => void;
}

const options = [
    'No technical difficulties',
    'The assessment disconnected',
    'The connection dropped in and out',
    'I couldn’t hear the assessor due to poor sound quality',
    'The assessor couldn’t hear me',
    'I wasn’t able to see the assessor due to poor image quality',
];

const Q3: React.FC<Props> = ({ onNext }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const onSelected = (option: string) => {
        if (selected.includes(option)) {
            setSelected(selected.filter(o => o !== option));
        } else {
            setSelected([...selected, option]);
        }
    };
    const onClick = () => {
        onNext({
            question3: selected.join(', '),
        });
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 3 of 7</h2>
                <p>
                    Did you experience any technical issues during the assessment? Please select all
                    that apply.
                </p>
                <div className="survey__options">
                    {options.map(option => (
                        <SurveyOption
                            key={option}
                            selected={selected.includes(option)}
                            onClick={() => onSelected(option)}
                        >
                            {option}
                        </SurveyOption>
                    ))}
                </div>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick} disabled={!selected}>
                    Next
                </CustomerButton>
            </div>
        </>
    );
};

export default Q3;
