import React from 'react';
import Video from '../Video';
import { useTiles } from 'context/Tiles';
import { Button } from '@missionlabs/smartagent-app-components';
//import LocalVideo from 'components/LocalVideo';

interface Props {
    width?: number | string;
    height?: number | string;
}
const SelectedTile: React.FC<Props> = ({ width, height }) => {
    const { tiles, selectedAttendee } = useTiles();
    const selected =
        tiles.find(t => t.AttendeeId === selectedAttendee) ||
        tiles.find(t => t.LocalTile) ||
        tiles[0];

    if (!selected) return null;
    return selected.LocalTile ? (
        <Video tileId={selected.tileId} width={width} height={height} className="selected-video" />
    ) : (
        // <LocalVideo  width={width} height={height} className="selected-video"/>
        <Video tileId={selected.tileId} width={width} height={height} />
    );
};

const OtherTiles: React.FC<Props> = ({ width, height }) => {
    const { tiles, setSelectedAttendee, selectedAttendee } = useTiles();
    const selected =
        tiles.find(t => t.AttendeeId === selectedAttendee) ||
        tiles.find(t => t.LocalTile) ||
        tiles[0];

    return (
        <>
            {tiles.map(tile =>
                tile.AttendeeId !== selected?.AttendeeId ? (
                    <Button
                        key={tile.AttendeeId}
                        onClick={() => setSelectedAttendee(tile.AttendeeId)}
                    >
                        {tile.LocalTile ? (
                            // <LocalVideo  width={width} height={height}/>
                            <Video tileId={tile.tileId} width={width} height={height} />
                        ) : (
                            <Video tileId={tile.tileId} width={width} height={height} />
                        )}
                    </Button>
                ) : null
            )}
        </>
    );
};

export { SelectedTile, OtherTiles };
