import React, { useState, useEffect } from 'react';
import { Button, Form, Input } from '@missionlabs/smartagent-app-components';
import { ClipLoader } from 'react-spinners';
import { useTiles } from 'context/Tiles';
import './add-participant.scss';
import { useMeetingStatus } from 'context/Meeting';

interface Props {
    initialNumber?: string;
    onAddParticipant: (number: string) => void;
    onDisconnect: (attendeeId: string) => void;
}

const AddParticipant: React.FC<Props> = ({ initialNumber, onAddParticipant, onDisconnect }) => {
    const [number, setNumber] = useState(initialNumber ?? '');
    const [inviteSent, setInviteSent] = useState(false);
    const { tiles } = useTiles();
    const status = useMeetingStatus();

    useEffect(() => {
        if (initialNumber) setNumber(initialNumber);
    }, [initialNumber]);

    useEffect(() => {
        //They've joined so clear the invite flag
        if (tiles.find(t => t.ExternalId === number)) {
            setInviteSent(false);
        }
        // eslint-disable-next-line
    }, [tiles]);

    useEffect(() => {
        if (status === 'ENDED') {
            setInviteSent(false);
        }
    }, [status]);

    const onSubmit = () => {
        onAddParticipant(number);
        if (!number) return;
        setInviteSent(true);
    };

    const AttendeeId = tiles.find(t => t.ExternalId === number)?.AttendeeId;

    const onClickDisconnect = () => {
        onDisconnect(AttendeeId!);
        setNumber('');
    };

    const isProd = process.env.NODE_ENV === 'production';

    return (
        <Form onSubmit={onSubmit} className="add-participant">
            <Input
                required={isProd}
                label="Add Participant"
                onChange={(value: any) => setNumber(value)}
                value={number}
                disabled={!!AttendeeId || inviteSent}
            />
            {AttendeeId ? (
                <Button
                    className="add-participant__add"
                    styling="red"
                    type="button"
                    onClick={onClickDisconnect}
                >
                    Disconnect
                </Button>
            ) : (
                <Button
                    className="add-participant__add"
                    styling="primary"
                    disabled={!number && isProd}
                >
                    {inviteSent ? <ClipLoader color="#fff" size={20} /> : 'Send invite'}
                </Button>
            )}
        </Form>
    );
};

export default AddParticipant;
