import React from 'react';
import { Button, Popup } from '@missionlabs/smartagent-app-components';
import CustomerButton from 'components/CustomerButton';
import './confirm-disconnect.scss';

interface Props {
    onClose: () => void;
    onDisconnect: () => void;
}
const ConfirmDisconnect: React.FC<Props> = ({ onClose, onDisconnect }) => {
    return (
        <Popup onClose={onClose} center className="confirm-disconnect">
            <section className="help">
                <h2 className="title">Are you sure you want to disconnect video chat?</h2>
                <p>
                    By clicking disconnect, you will be unable to see the health professional and
                    they will be unable to see you.
                </p>
                <div className="confirm-disconnect__buttons">
                    <CustomerButton alt onClick={onClose}>
                        Close
                    </CustomerButton>
                    <Button
                        className="confirm-disconnect__confirm"
                        styling="red"
                        onClick={onDisconnect}
                    >
                        Disconnect
                    </Button>
                </div>
            </section>
        </Popup>
    );
};

export default ConfirmDisconnect;
