import { useEffect, useState } from 'react';

export const on = (obj: any, ...args: any[]) => obj.addEventListener(...args);

export const off = (obj: any, ...args: any[]) => obj.removeEventListener(...args);

export interface OrientationState {
    angle: number;
    type: string;
    windowHeight: number;
}

const defaultState: OrientationState = {
    angle: 0,
    type: 'landscape-primary',
    windowHeight: window.innerHeight,
};

const useOrientation = (initialState: OrientationState = defaultState) => {
    const screen = window.screen;
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let mounted = true;

        const onChange = () => {
            if (mounted) {
                const { orientation } = screen as any;

                if (orientation) {
                    const { angle, type } = orientation;
                    setTimeout(() => {
                        setState({ angle, type, windowHeight: window.innerHeight });
                    }, 500);
                } else if (window.orientation) {
                    setState({
                        angle: typeof window.orientation === 'number' ? window.orientation : 0,
                        type: '',
                        windowHeight: window.innerHeight,
                    });
                } else {
                    setState(initialState);
                }
            }
        };

        on(window, 'orientationchange', onChange);
        onChange();

        return () => {
            mounted = false;
            off(window, 'orientationchange', onChange);
        };
        // eslint-disable-next-line
    }, []);

    return state;
};

export default useOrientation;
