import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
import SurveyOption from 'components/SurveyOption';
import { Results } from '.';

interface Props {
    onNext: (results: Results) => void;
}

const options = ['Very Easy', 'Easy', 'Satisfactory', 'Difficult', 'Very Difficult'];

const Q2: React.FC<Props> = ({ onNext }) => {
    const [selected, setSelected] = useState('');
    const onClick = () => {
        onNext({
            question2: selected,
        });
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 2 of 7</h2>
                <p>
                    How helpful did you find the instructions you received about how to join the
                    assessment?
                </p>
                <div className="survey__options">
                    {options.map(option => (
                        <SurveyOption
                            key={option}
                            selected={option === selected}
                            onClick={() => setSelected(option)}
                        >
                            {option}
                        </SurveyOption>
                    ))}
                </div>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick} disabled={!selected}>
                    Next
                </CustomerButton>
            </div>
        </>
    );
};

export default Q2;
