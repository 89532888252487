import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
import SurveyOption from 'components/SurveyOption';
import { Results } from '.';
interface Props {
    onNext: (result: Results) => void;
}

const options = ['Yes', 'To some extent', 'Not at all'];

const Q4: React.FC<Props> = ({ onNext }) => {
    const [selected, setSelected] = useState('');
    const onClick = () => {
        onNext({
            question4: selected,
        });
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 4 of 7</h2>
                <p>
                    During the assessment, did you feel able to explain how your health condition or
                    disability affects your daily life?
                </p>
                <div className="survey__options">
                    {options.map(option => (
                        <SurveyOption
                            key={option}
                            selected={option === selected}
                            onClick={() => setSelected(option)}
                        >
                            {option}
                        </SurveyOption>
                    ))}
                </div>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick} disabled={!selected}>
                    Next
                </CustomerButton>
            </div>
        </>
    );
};

export default Q4;
