import React from 'react';
import { Button } from '@missionlabs/smartagent-app-components';
import './survey-option.scss';

interface Props {
    selected?: boolean;
    onClick: () => void;
}

const SurveyOption: React.FC<Props> = props => {
    return (
        <Button
            className={`survey-option${props.selected ? ' selected' : ''}`}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export default SurveyOption;
