import React from 'react';
import './survey.scss';

const Complete: React.FC = () => {
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Thank you!</h2>
                <p>
                    Thank you for completing this survey. Your responses will remain confidential.
                </p>
            </div>
        </>
    );
};

export default Complete;
