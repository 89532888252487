import React, { useEffect, useState } from 'react';
import Question1 from './question1';
import Question2 from './question2';
import Question3 from './question3';
import Question4 from './question4';
import Question5 from './question5';
import Question6 from './question6';
import Question7 from './question7';
import Intro from './intro';
import Complete from './complete';
import './survey.scss';

export type Results = Record<string, string>;

interface Props {
    showSurvey?: boolean;
    onComplete: (results: Results) => void;
}
const Survey: React.FC<Props> = ({ showSurvey, onComplete }) => {
    const [question, setQuestion] = useState(0);
    const [results, setResults] = useState({});
    const onNext = (result?: Results) => {
        setQuestion(question + 1);
        if (result) {
            setResults({
                ...results,
                ...result,
            });
        }
    };

    useEffect(() => {
        if (question === 8) {
            onComplete(results);
        }
        // eslint-disable-next-line
    }, [question]);

    const renderQuestion = () => {
        if (!showSurvey) return <Complete />;
        switch (question) {
            case 0:
                return <Intro onNext={onNext} />;
            case 1:
                return <Question1 onNext={onNext} />;
            case 2:
                return <Question2 onNext={onNext} />;
            case 3:
                return <Question3 onNext={onNext} />;
            case 4:
                return <Question4 onNext={onNext} />;
            case 5:
                return <Question5 onNext={onNext} />;
            case 6:
                return <Question6 onNext={onNext} />;
            case 7:
                return <Question7 onNext={onNext} />;
            default:
                return <Complete />;
        }
    };
    return <section className="survey">{renderQuestion()}</section>;
};

export default Survey;
