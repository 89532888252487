import { load, BodyPix } from '@tensorflow-models/body-pix';

let bp: BodyPix;

async function loadBodyPix(): Promise<BodyPix> {
    return new Promise(async resolve => {
        if (bp) return resolve(bp);
        bp = await load({
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.75,
            quantBytes: 2,
        });
        return resolve(bp);
    });
}

export { BodyPix };

export default loadBodyPix;
