import { useAudioVideo } from 'context/AudioVideo';
import { useDevices } from 'context/Devices';
import { useTiles } from 'context/Tiles';
import React, { useRef, useEffect, useMemo, useState } from 'react';
import loadBodyPix, { BodyPix } from 'services/body-pix';
import { drawBokehEffect } from '@tensorflow-models/body-pix';
import Video from '../Video';

interface Props {
    width?: number | string;
    height?: number | string;
    className?: string;
}

// const video = document.createElement('video');
// video.loop=true;

const LocalVideo: React.FC<Props> = ({ width, height, className }) => {
    const canvasEl = useRef<null | HTMLCanvasElement>(null);
    const af = useRef<null | number>(null);
    const net = useRef<null | BodyPix>(null);
    const { tiles } = useTiles();
    const { blur, deviceId } = useDevices();
    const localVideo = useMemo(() => tiles.find(t => t.LocalTile), [tiles]);
    const [video, setVideo] = useState(document.createElement('video'));
    const av = useAudioVideo();

    useEffect(() => {
        if (blur && av && localVideo) doBlur();
        // eslint-disable-next-line
    }, [blur, av, localVideo]);

    const wait = (video: HTMLVideoElement) => {
        if (video.readyState === 4) {
            return;
        }
        return new Promise(resolve => (video.onloadeddata = resolve));
    };

    const doBlur = async () => {
        setVideo(document.createElement('video'));
        const params = deviceId
            ? {
                  video: {
                      advanced: [
                          {
                              deviceId,
                          },
                      ],
                  },
              }
            : {
                  video: true,
              };
        const stream = await navigator.mediaDevices.getUserMedia({ ...params, audio: false });
        const settings = stream.getVideoTracks()[0].getSettings();
        video.width = settings.width!;
        video.height = settings.height!;
        video.srcObject = stream;
        net.current = await loadBodyPix();
        await wait(video);
        video.play();

        requestAnimationFrame(animate);
        //@ts-ignore
        av?.chooseVideoInputDevice(canvasEl.current?.captureStream());
    };

    const animate = async () => {
        const segmentation = await net.current!.segmentPerson(video, {
            segmentationThreshold: 0.3,
            maxDetections: 1,
            flipHorizontal: true,
        });
        const backgroundBlurAmount = 15;
        const edgeBlurAmount = 10;
        const flipHorizontal = true;
        // Draw the image with the background blurred onto the canvas. The edge between
        // the person and blurred background is blurred by 3 pixels.
        if (!canvasEl.current) return;
        drawBokehEffect(
            canvasEl.current,
            video,
            segmentation,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal
        );
        if (!blur) return;
        af.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        return () => cancelAnimationFrame(af.current!);
    }, []);

    if (!localVideo) return null;
    return (
        <>
            {blur ? (
                <canvas ref={canvasEl} height={height} width={width} className={className} />
            ) : (
                <Video
                    tileId={localVideo.tileId}
                    width={width}
                    height={height}
                    className={className}
                />
            )}
        </>
    );
};

export default LocalVideo;
