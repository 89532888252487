import React, { createContext, useContext } from 'react';
import { AudioVideoFacade } from 'amazon-chime-sdk-js';

import { MeetingContext } from '../Meeting';

type AudioVideoValue = AudioVideoFacade | null;

export const AudioVideoContext = createContext<AudioVideoValue>(null);

const AudioVideoProvider: React.FC = ({ children }) => {
    const { audioVideo } = useContext(MeetingContext);
    return <AudioVideoContext.Provider value={audioVideo}>{children}</AudioVideoContext.Provider>;
};

const useAudioVideo = (): AudioVideoValue => {
    const audioVideo = useContext(AudioVideoContext);

    return audioVideo;
};

export { useAudioVideo, AudioVideoProvider };
