import React, { useMemo } from 'react';
import AgentView from './views/Agent';
import CustomerView from './views/Customer';
import { Box } from '@missionlabs/smartagent-app-components';
import { getParameterByName } from './utils';
import { useContact } from 'context/SmartAgent';

const isEmbedded = window.parent !== window;
const meetingId = getParameterByName('meetingId') ?? undefined;
const externalId = getParameterByName('externalId') ?? undefined;
const contactId = getParameterByName('contactId') ?? undefined;

const App: React.FC<{}> = () => {
    const contact = useContact();

    const contactID = useMemo(() => contact?.ID, [contact]);

    return (
        <>
            {isEmbedded ? (
                <AgentView
                    number={contact?.customerEndpointAddress}
                    contactId={contactID ?? contactId}
                />
            ) : (meetingId && externalId) || process.env.REACT_APP_ENV === 'prod' ? (
                <CustomerView
                    meetingId={meetingId!}
                    externalId={externalId}
                    contactId={contactID ?? contactId}
                />
            ) : (
                <Box alt header={<h1>Video Calling</h1>} collapse>
                    <AgentView meetingId={meetingId} contactId={contactID ?? contactId} />
                </Box>
            )}
        </>
    );
};

export default App;
