import CustomerButton from 'components/CustomerButton';
import React from 'react';
import './terms.scss';

interface Props {
    onNext: () => void;
}
const Terms: React.FC<Props> = ({ onNext }) => {
    return (
        <section className="terms">
            <div className="terms__body">
                <h2 className="title">Before we start</h2>
                <p>
                    By taking part in this assessment you agree not to make any covert photographs
                    or recordings, consent has not been given under GDPR
                </p>
            </div>
            <div className="terms__continue">
                <CustomerButton onClick={onNext}>Agree &amp; Continue</CustomerButton>
            </div>
        </section>
    );
};

export default Terms;
