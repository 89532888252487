import React, { useContext, useEffect, useState } from 'react';
import { useAudioVideo } from 'context/AudioVideo';

export interface IDevicesContext {
    onSwitchCamera: (deviceId: string) => void;
    devices: MediaDeviceInfo[];
    deviceId: string;
    toggleBlur: () => void;
    blur: boolean;
}

export const DevicesContext = React.createContext<IDevicesContext>({} as any);

const DevicesProvider: React.FC = ({ children }) => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [deviceId, setDeviceId] = useState<string>('');
    const [blur, setBlur] = useState<boolean>(true);
    const audioVideo = useAudioVideo();

    useEffect(() => {
        if (!audioVideo) return;
        init();
        // eslint-disable-next-line
    }, [audioVideo]);

    const init = async () => {
        if (!audioVideo) return;
        const videoInputDevices = await audioVideo.listVideoInputDevices();
        setDeviceId(videoInputDevices[0].deviceId);
        setDevices(videoInputDevices);
    };

    useEffect(() => {
        if (!deviceId || blur) return;
        audioVideo?.chooseVideoInputDevice(deviceId);
        // eslint-disable-next-line
    }, [deviceId, blur]);

    const onSwitchCamera = (deviceId: string) => {
        setDeviceId(deviceId);
    };

    const toggleBlur = () => {
        setBlur(!blur);
    };

    return (
        <DevicesContext.Provider
            value={{
                onSwitchCamera,
                devices,
                deviceId,
                toggleBlur,
                blur,
            }}
        >
            {children}
        </DevicesContext.Provider>
    );
};

const useDevices = (): IDevicesContext => {
    const devices = useContext(DevicesContext);

    return devices;
};

export { DevicesProvider, useDevices };
