import React, { useEffect, useContext, useReducer } from 'react';
import { AudioVideoObserver } from 'amazon-chime-sdk-js';
import { useAudioVideo } from 'context/AudioVideo';
import reducer, { VideoTileActionType, Tile, initialState } from './reducer';


interface ITilesContext{
    selectedAttendee:string;
    setSelectedAttendee:(tileId:string)=>void;
    tiles:Tile[]
}

export const TilesContext = React.createContext<ITilesContext>({} as any);

const TilesProvider: React.FC = ({ children }) => {
    const audioVideo = useAudioVideo();
    const [state, dispatch] = useReducer(reducer, initialState);
    
    const observer: AudioVideoObserver = {
        // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
        videoTileDidUpdate: tileState => {
            // Ignore a tile without attendee ID and other attendee's tile.
            if (!tileState.boundAttendeeId || !tileState.tileId) {
                return;
            }
            dispatch({
                type: VideoTileActionType.UPDATE,
                payload: {
                    tileId: tileState.tileId,
                    AttendeeId: tileState.boundAttendeeId,
                    ExternalId: tileState.boundExternalUserId!,
                    LocalTile: tileState.localTile
                },
            });
        },
        videoTileWasRemoved: tileId => {
            // const tile = tiles.find(t=>t.tileId === tileId);
            dispatch({
                type: VideoTileActionType.REMOVE,
                payload: tileId,
            });
        },
    };
    useEffect(() => {
        if (!audioVideo) return;
        audioVideo.addObserver(observer);
        return () => audioVideo?.removeObserver(observer);
        // eslint-disable-next-line
    }, [audioVideo]);

    const setSelectedAttendee = (id:string)=>{
        dispatch({
            type: VideoTileActionType.SELECT,
            payload: id
        })
    }

    return <TilesContext.Provider value={{
        tiles:state.tiles,
        setSelectedAttendee:setSelectedAttendee,
        selectedAttendee: state.selectedAttendee
    }}>{children}</TilesContext.Provider>;
};

const useTiles = (): ITilesContext => {
    const Tiles = useContext(TilesContext);

    return Tiles;
};

export { TilesProvider, useTiles };
