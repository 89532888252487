import React, { useState, useEffect } from 'react';
import {
    startMeeting,
    MeetingResponse,
    joinMeeting,
    deleteAttendee,
    sendInvite,
    deleteMeeting,
} from 'services/api';
import AgentMeeting from './AgentMeeting';
import AddParticipant from './AddParticipant';
import './agent.scss';
import { useMeetingStatus } from 'context/Meeting';

interface Props {
    meetingId?: string;
    number?: string;
    contactId?: string;
}

const App: React.FC<Props> = ({ meetingId, number, contactId }) => {
    const [meeting, setMeeting] = useState<MeetingResponse | null>(null);
    const status = useMeetingStatus();
    //const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (meetingId) {
            loadMeeting(meetingId);
        }
    }, [meetingId]);

    const loadMeeting = async (meetingId: string) => {
        const m = await joinMeeting(meetingId, {});
        setMeeting(m);
    };

    const onAddParticipant = async (number: string) => {
        if (meeting) {
            await sendInvite(meeting.Meeting.MeetingId, { number, contactId });
        } else {
            const m = await startMeeting({
                number,
                contactId,
            });
            setMeeting(m);
        }
    };

    const onDisconnect = async (attendeeId: string) => {
        await deleteAttendee(meeting?.Meeting.MeetingId, attendeeId);
    };

    useEffect(() => {
        if (status === 'ENDED') {
            onEnded();
        }
        // eslint-disable-next-line
    }, [status]);
    const onEnded = async () => {
        await deleteMeeting(meeting?.Meeting.MeetingId);
        setMeeting(null);
    };

    return (
        <>
            {meeting ? (
                <AgentMeeting meeting={meeting} />
            ) : (
                <section className="not-connected">NOT CONNECTED</section>
            )}
            <div className="add-participants">
                <AddParticipant
                    initialNumber={number}
                    onAddParticipant={onAddParticipant}
                    onDisconnect={onDisconnect}
                />
                <AddParticipant onAddParticipant={onAddParticipant} onDisconnect={onDisconnect} />
            </div>
        </>
    );
};

export default App;
