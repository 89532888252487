import React, { useState, useRef, useContext, useEffect } from 'react';
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    AudioVideoFacade,
    AudioVideoObserver,
} from 'amazon-chime-sdk-js';
import { MeetingResponse } from '../../services/api';
import { AudioVideoProvider } from 'context/AudioVideo';
import { DevicesProvider } from 'context/Devices';
import { TilesProvider } from 'context/Tiles';

const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);

export interface IMeetingContext {
    onStart: (meeting: MeetingResponse) => void;
    audioVideo: AudioVideoFacade | null;
    onEnd: () => void;
    status: MeetingStatus;
}
export type MeetingStatus = 'NONE' | 'CONNECTED' | 'ENDED';
export const MeetingContext = React.createContext<IMeetingContext>({} as any);

const MeetingProvider: React.FC = ({ children }) => {
    const [audioVideo, setAudioVideo] = useState<AudioVideoFacade | null>(null);
    const session = useRef<null | DefaultMeetingSession>(null);
    const [status, setStatus] = useState<MeetingStatus>('NONE');

    const onStart = async (meeting: MeetingResponse) => {
        setStatus('NONE');
        const configuration = new MeetingSessionConfiguration(meeting.Meeting, meeting.Attendee);

        const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
        meetingSession.deviceController.setDeviceLabelTrigger(() => {
            return navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        });
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        await meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
        meetingSession.audioVideo.startLocalVideoTile();
        meetingSession.audioVideo.start();

        setAudioVideo(meetingSession.audioVideo);

        session.current = meetingSession;
    };

    const onEnd = () => {
        audioVideo?.stop();
    };

    useEffect(() => {
        if (!audioVideo) return;
        audioVideo.addObserver(observer);
        return () => audioVideo?.removeObserver(observer);
        // eslint-disable-next-line
    }, [audioVideo]);

    const observer: AudioVideoObserver = {
        audioVideoDidStart: () => {
            setStatus('CONNECTED');
        },
        audioVideoDidStop: () => {
            setStatus('ENDED');
        },
    };

    return (
        <MeetingContext.Provider
            value={{
                onStart,
                audioVideo,
                onEnd,
                status,
            }}
        >
            <AudioVideoProvider>
                <TilesProvider>
                    <DevicesProvider>{children}</DevicesProvider>
                </TilesProvider>
            </AudioVideoProvider>
        </MeetingContext.Provider>
    );
};

const useMeeting = (): IMeetingContext => {
    const meeting = useContext(MeetingContext);

    return meeting;
};

const useMeetingStatus = (): MeetingStatus => {
    const meeting = useContext(MeetingContext);

    return meeting.status;
};

export { useMeeting, useMeetingStatus, MeetingProvider };
