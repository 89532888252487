import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
import SurveyOption from 'components/SurveyOption';
import { Results } from '.';
interface Props {
    onNext: (result: Results) => void;
}

const options = ['Very satisfied', 'Satisfied', 'Dissatisfied', 'Very dissatisfied'];

const Q5: React.FC<Props> = ({ onNext }) => {
    const [selected, setSelected] = useState('');
    const onClick = () => {
        onNext({
            question5: selected,
        });
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 5 of 7</h2>
                <p>
                    Overall, how satisfied or dissatisfied were you with your experience of having
                    an assessment by video?
                </p>
                <div className="survey__options">
                    {options.map(option => (
                        <SurveyOption
                            key={option}
                            selected={option === selected}
                            onClick={() => setSelected(option)}
                        >
                            {option}
                        </SurveyOption>
                    ))}
                </div>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick} disabled={!selected}>
                    Next
                </CustomerButton>
            </div>
        </>
    );
};

export default Q5;
