import React from 'react';
import RemoteVideo from '../Video';
import { useTiles } from 'context/Tiles';

interface Props {
    width?: number | string;
    height?: number | string;
}

const RemoteVideos: React.FC<Props> = ({ width, height }) => {
    const { tiles } = useTiles();

    return (
        <>
            {tiles.filter(t=>!t.LocalTile).map(tile => (
                <RemoteVideo key={tile.tileId} tileId={tile.tileId} width={width} height={height} />
            ))}
        </>
    );
};

export default RemoteVideos;
