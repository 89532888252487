import React, { useState } from 'react';
import Logo from 'images/logo.svg';
import HelpIcon from 'images/help.svg';
import './header.scss';
import { Button } from '@missionlabs/smartagent-app-components';
import Help from 'components/Help';

const Header: React.FC = () => {
    const [showHelp, setShowHelp]=useState(false);
    const onToggle = ()=>{
        setShowHelp(!showHelp);
    }
    return (
        <header className="header">
            <img src={Logo} alt="Capita" height="24" />
            <Button onClick={onToggle}>
                <img src={HelpIcon} alt="Help" height="24" />
            </Button>
            {showHelp && <Help onClose={onToggle}/>}
        </header>
    );
};

export default Header;
