import React, { useEffect, useState } from 'react';
import { MeetingResponse } from 'services/api';
import { useMeeting } from 'context/Meeting';
import { Button } from '@missionlabs/smartagent-app-components';
import EndIcon from 'images/end.svg';
import BlurIcon from 'images/blur.svg';
import CameraIcon from 'images/camera.svg';
import './customer-meeting.scss';
import { useDevices } from 'context/Devices';
import useOrientation from 'hooks/useOrientation';
import { SelectedTile, OtherTiles } from 'components/SelectedTiles';
import ConfirmDisconnect from './ConfirmDisconnect';

interface Props {
    meeting: MeetingResponse;
}

const CustomerMeeting: React.FC<Props> = ({ meeting }) => {
    const { onStart, onEnd } = useMeeting();
    const { devices, onSwitchCamera, deviceId, toggleBlur } = useDevices();
    const { type } = useOrientation();
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        onStart(meeting);
        // eslint-disable-next-line
    }, [meeting]);

    const onSwitch = () => {
        const device = devices.find(d => d.deviceId !== deviceId);
        if (device) onSwitchCamera(device.deviceId);
    };

    const onToggleConfirm = () => {
        setConfirm(!confirm);
    };

    const isLandscape = type.includes('landscape');

    return (
        <section className="customer-meeting">
            <div className={`customer-meeting__remote ${isLandscape ? 'landscape' : ''}`}>
                <OtherTiles width={'auto'} height={'auto'} />
            </div>
            <SelectedTile width="100%" height={'auto'} />
            <div className="customer-meeting__controls">
                {/* <Button className="customer-meeting__blur" onClick={toggleBlur}>
                    <img src={BlurIcon} alt="Blur" />
                </Button> */}
                <Button className="customer-meeting__end" onClick={onToggleConfirm}>
                    <img src={EndIcon} alt="End" />
                </Button>
                {devices.length > 1 && (
                    <Button className="customer-meeting__switch" onClick={onSwitch}>
                        <img src={CameraIcon} alt="Swap Camera" />
                    </Button>
                )}
            </div>
            {confirm && <ConfirmDisconnect onClose={onToggleConfirm} onDisconnect={onEnd} />}
        </section>
    );
};

export default CustomerMeeting;
