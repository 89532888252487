import React, { useState } from 'react';
import CustomerButton from 'components/CustomerButton';
import './survey.scss';
import SurveyOption from 'components/SurveyOption';
import { Results } from '.';
interface Props {
    onNext: (result: Results) => void;
}

const options = [
    'Telephone Assessment',
    'Video Assessment',
    'Face to Face Assessment (in your home)',
    'Face to Face Assessment (at one of our centres)',
];

const Q6: React.FC<Props> = ({ onNext }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [noPreference, setNoPreference] = useState(false);
    const onClick = () => {
        onNext({
            question6: selected.join(','),
        });
    };
    const onNoPreference = () => {
        setNoPreference(true);
        setSelected([]);
    };
    const onSelected = (option: string) => {
        setNoPreference(false);
        if (selected.includes(option)) {
            setSelected(selected.filter(s => s !== option));
        } else {
            setSelected([...selected, option]);
        }
    };
    return (
        <>
            <div className="survey__body">
                <h2 className="title">Question 6 of 7</h2>
                <p>
                    Based on your experience today, please put the below assessment methods in order
                    of preference. (1 being your most preferred method and 4 being your least
                    preferred method)
                </p>
                <div className="survey__options">
                    {options.map(option => (
                        <div className="survey__ranking" key={option}>
                            <SurveyOption
                                selected={selected.includes(option)}
                                onClick={() => onSelected(option)}
                            >
                                {option}
                            </SurveyOption>
                            {selected.includes(option) && (
                                <span className="survey__number">
                                    {selected.indexOf(option) + 1}
                                </span>
                            )}
                        </div>
                    ))}
                    <SurveyOption selected={noPreference} onClick={onNoPreference}>
                        No Preference
                    </SurveyOption>
                </div>
            </div>
            <div className="survey__continue">
                <CustomerButton onClick={onClick} disabled={selected.length === 0 && !noPreference}>
                    Next
                </CustomerButton>
            </div>
        </>
    );
};

export default Q6;
