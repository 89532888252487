import React from 'react';
import { Button } from '@missionlabs/smartagent-app-components';
import './customer-button.scss';

interface Props {
    disabled?: boolean;
    onClick: () => void;
    alt?: boolean;
}

const CustomerButton: React.FC<Props> = props => {
    return (
        <Button className={`customer-button${props.alt ? ' alt' : ''}`} disabled={props.disabled} onClick={props.onClick}>
            {props.children}
        </Button>
    );
};

export default CustomerButton;
