import React from 'react';
import './link-expired.scss';

const LinkExpired: React.FC = () => {
    return (
        <section className="link-expired">
            <h2 className="title">Link expired</h2>
            <p>This link has now expired.</p>
        </section>
    );
};

export default LinkExpired;
